<template>
	<div id="app">
		<!-- <Header></Header> -->
		<router-view />
		<!-- <Footer></Footer> -->
		<!-- <GoTop></GoTop> -->
	</div>
</template>

<script>
export default {
	data() {
		return {};
	}
};
</script>
